import React from "react"
import { Link } from "gatsby"

import HeaderResources from "../../../components/header_resources"

export default function InfluenceThePsychology() {
  const heroTitle = "Influence – The Psychology of Persuasion by Robert B. Cialdini (RBC)"

  return (
    <HeaderResources heroTitle={heroTitle} layout="1Column">
      <div class="w-100 mb-5 sm:mmb-5">
        <Link class="back--home" to="/resources/book-summaries">
          <i class="fas fa-chevron-left"></i> <span>Back to Book Summaries</span>
        </Link>
      </div>
      <div class="innerpage--content innerpage--content-sm blog--details m-0 p-0">
        <h2 class="h2 h2-sm">{heroTitle}</h2>
        <p>
          I have set out below a summary, key takeaways / learning outcomes, some ramblings and
          unanswered questions.
        </p>
        <p>
          As a general comment, RBC makes a very pertinent point upfront when he says that amongst
          the many principles or weapons of influence, the most powerful is the one of “material
          self-interest”. It is quite true that for humans (a form of an animal) self-interest in a
          number of cases is considered as a self-preservation technique and drives our actions and
          reactions. Of course, all of these principles and weapons are likely to apply in a
          majority and not all of the cases nor to everyone with the same degree.
        </p>

        <h3>Chapter 1 – Weapons of Influence</h3>
        <p>
          Most people have automatic / pre-programmed compliance triggers which makes them
          susceptible to acting or reacting without much thought. Stereotyped behavior is prevalent
          in human actions because in most case it seems to be the most efficient and simplest form
          of behaving.{" "}
        </p>
        <p>
          This behavior also seems to stem from brains being cluttered with a lot of needless stuff
          and such pre-programmed automatic behavior helps in avoiding further brain strain.
          Therefore, to my mind, the less you cabbage up your mind the better it is for your
          decision making and thinking skills.
        </p>

        <h3>Chapter 2 – Reciprocation</h3>
        <p>
          The rule of reciprocation (i.e. we should repay in kind what another person has provided
          us) has been given importance on account of the need of humans to be sociable.
          Reciprocation is overpowering in its influence since it generates an existing feeling of
          indebtedness and even trumps the fact whether or not you like the person to whom you may
          feel indebted to.
        </p>
        <p>
          It becomes more effective as a tool of influence since it has the “benefactor before
          beggar” principle built into the construct. The reciprocity rule also governs many
          situations of a purely interpersonal nature where neither money nor commercial exchange is
          at issue.{" "}
        </p>
        <p>
          RBC explains that although the obligation to repay constitutes the essence of the
          reciprocity rule, it is the obligation to receive that makes the rule easy to exploit
          since this reduces our ability to choose whom we wish to be indebted to and puts that
          power in the hands of others. It is true that even uninvited gifts produce a feeling of
          obligation and then one gets the feeling of trying to “better the gift” that has been
          received.
        </p>
        <p>
          This entire behavioural pattern also arises because of the tendency of humans to respond
          more to negative feelings. The fact that we will be shamed if we don’t reciprocate
          gestures or gifts leads to psychological discomfort.
        </p>
        <p>
          The key point is that while favours can be met with favours but “tricks” should not be
          responded to with favours.
        </p>

        <h3>Chapter 3 – Commitment and Consistency</h3>
        <p>
          This one seems to be the most potent and from a personal experience perspective – the most
          influential. I agree with RBC when he says that for most people it is the obsessive desire
          to be (and to appear) consistent with what we have already done and, once we have made a
          choice or taken a stand, that makes us encounter personal and interpersonal pressure to
          behave consistently with that commitment. From an investment perspective, this may cloud
          judgment when you try and give a positive spin to negative developments in a business /
          company and which may anchor you to a bad investment decision you may have made.
        </p>
        <p>
          The need for consistency stems from the fact that inconsistency is thought to be an
          undesirable personality trait and as social animals we like people to like us and
          therefore suppress or do not gravitate towards anything that would make us unlikeable.
        </p>
        <p>
          This influence tool works at a more deeper level as it has the potential to
          sub-consciously change the perception one has of one’s own self in one’s own eyes and then
          brings about the tendency to live up to that “cooked up” perception. Written statements
          and public commitments are more powerful in making a person stick to her stand.
        </p>
        <p>
          The biggest motivation for making a commitment and remaining loyally consistent with it
          also arises if that commitment is driven from an inner choice - where we are prepared to
          be internally responsible for our actions.
        </p>

        <h3>Chapter 4 – Social Proof</h3>
        <p>
          This was the simplest principle to understand but it was quite fascinating to read that
          even what is considered as apathy at times arises because of the desire to seek social
          proof of one’s actions. Social proof or social evidence is used by us to assure us that we
          prefer to be true will seem to be true i.e. we like to validate our own biases.
        </p>
        <p>
          Uncertainty breeds the desire to look to others for behavior and actions and seek social
          proof. From an investing perspective, this explains why people jump into the well together
          in case of volatility. Following the collective knowledge of the crowd can often lead to
          sub-optimal results as the crowd may not be acting on any superior information.
        </p>
        <p>
          Similarity also triggers social proof type behavior and we are more inclined to follow the
          lead of a similar individual – very important from a leadership trait perspective though.
        </p>

        <h3>Chapter 5 – Liking</h3>
        <p>
          It is quite natural to be influenced by people whom you like and the feeling of likeness
          can be generated on account of various, including superficial, factors. RBC also explains
          about the Halo Effect of some people – when one positive characteristic of a person
          dominates the way that person is viewed by others. A smooth talking CEO can create a halo
          effect on analysts’ analysis re the company / business leading to sub-optimal analysis!
        </p>
        <p>
          People have an automatic impulse of assigning favourable traits to good looking (I will
          add good sounding as well) individuals and therefore, good looks helps you in being
          persuasive and getting more favourable treatment . We also tend to like people with whom
          we share a certain sense of similarity. Compliments, flattery, ass licking are a powerful
          influential tool given that as humans we are suckers for flattery – like Aamir Khan
          indulges in Andaz Apna Apna with “aap gyaani hain, antaryami hain…aap purush hi nahin,
          mahapurush hain!!!”
        </p>
        <p>
          The principle of association governs both negative and positive connections. For instance,
          people think that we have the same personality traits as our friends. But to an extent it
          is true that we tend to associate with “our kind” of people. In those cases we associate
          our traits with those of others – “the self is at stake”. If we surround ourselves with
          success of others that we are connected with us in even a superficial way, our public
          prestige will rise. But we bury any negative connections with failures. This behavior can
          stem from lack of self-worth.
        </p>
        <p>
          RBC says that we have to let this weapon of influence work and once we know that “liking”
          has been produced do we need to take steps from being influenced solely on account of such
          liking.{" "}
        </p>

        <h3>Chapter 6 – Authority</h3>
        <p>
          This tool of influence gets to work from the day we are born and its power continues to
          grow because of a deep-seated sense of duty to authority within us all. In a majority of
          cases, succumbing to this weapon of influence is necessary for an orderly conduct of the
          society as a whole, however, in a number of cases, discarding obedience to authority would
          be the more prudent option to adopt. Acting on the basis of authority is reactive and not
          a result of thinking and therefore, mindless obedience can result in harm.
        </p>
        <p>
          Command of a higher authority – something that exists outside of us – has the potential to
          trump the command of inner consciousness and morality – something that exists within us,
          this is because our response to authority is automatic in a click and whirr fashion.
        </p>
        <p>
          RBC explains that even appearance and not possession of real authority is persuasive
          enough for us to act under the influence of authority. Possession of aspirational
          materialistic things by an “authority” figure provides a semblance of authority to the
          possessor and captivates us into submitting before her authority and the influence of
          authority not only works forcefully but also unexpectedly.
        </p>
        <p>
          As part of protective tactics, RBC suggests that one should reorient one’s thinking in
          such cases towards the authority figure’s credentials and the relevance of those
          credentials to the topic at hand. The credentials must have a direct relation to the
          authority and one should develop the ability to distinguish between relevant and
          irrelevant authorities. Trustworthiness of the authority’s source is of paramount
          importance.
        </p>

        <h3>Chapter 7 – Scarcity</h3>
        <p>
          Something expensive and / or scarce always appeals to people as being more valuable.
          Imperfections that would otherwise make for rubbish make for prized possessions when they
          bring along an abiding scarcity. People end up buying junk because it is scarce!! People
          put money into hugely run up stocks for fear of losing out on further prospective profits.{" "}
        </p>
        <p>
          RBC explains that the scarcity principle works on two major sources – (i) our weakness for
          seeking shortcuts (if it is scarce it must be valuable); and (ii) the hatred for losing
          the freedom we already have (the fact that something may not be available in the future
          makes us fight for it in the present). This is explained by the diminishing personal
          control theory – whenever free choice is limited or threatened, the need to retain our
          freedoms makes us desire them significantly more than before. Competition for a scarce
          resource makes it even more valuable.
        </p>
        <p>
          RBC advises extreme caution whenever one encounters the devilish construction of scarcity
          plus rivalry. He says that knowing how scarcity works on us isn’t enough because knowledge
          is a cognitive thing and once the principle springs into action our emotional reaction to
          scarcity overpowers our cognitive functions. In such a situation it becomes pertinent to
          distinguish whether the scarce item has possessory value or utility value for us. The
          latter is what should form the basis of our responses and actions.
        </p>
        <p>
          RBC concludes by suggesting that while these weapons aide us in most cases and help us in
          progressing in life without making much effort for mundane decisions, we should be ready
          for counterassault when these weapons are being used against us to trigger sub-optimal and
          self-destructive responses and actions.
        </p>
      </div>
    </HeaderResources>
  )
}
